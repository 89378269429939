"use client";

import React, { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const BookingClass = ({ bookingClass, setBookingClass }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // useEffect(() => {
  //   const getValueOfTravelType = localStorage.getItem("type");
  //   if (getValueOfTravelType) {
  //     setType(getValueOfTravelType);
  //   }
  // }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown and button
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative">
      {/* Toggle Button */}
      <div
        className="md:w-full flex gap-2 bg-[#e1eee8] text-black text-xs justify-center items-center text-center py-1.5 md:py-1 px-2 mb-1 rounded-xl cursor-pointer"
        ref={buttonRef}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <h3 className="capitalize">{bookingClass}</h3>
        {isDropdownOpen ? (
          <MdOutlineKeyboardArrowUp fontSize={20} />
        ) : (
          <MdOutlineKeyboardArrowDown fontSize={20} />
        )}
      </div>

      {/* Traveler Dropdown */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="mx-auto bg-white text-black rounded-lg overflow-hidden shadow-lg w-max md:w-64 absolute right-0 md:left-0  z-10"
        >
          {bookingClasses.map((cabin, index, array) => {
            return (
              <div key={cabin}>
                <div className="flex items-center ">
                  <button
                    onClick={() => {
                      setBookingClass(cabin);
                      setIsDropdownOpen(false);
                    }}
                    className={`p-3 text-sm  duration-100 border-b ${
                      index === array.length - 1 ? "border-b-0" : ""
                    } hover:bg-gray-200 w-full text-left cursor-pointer`}
                  >
                    {cabin}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BookingClass;

const bookingClasses = [
  // "Premium First",
  "Economy",
  "Premium Economy",
  "Business",
  "First",
  // "Premium Business",
];
