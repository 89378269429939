"use client";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { toast } from "react-toastify";

const TravelarCounter = ({ travelerCounts, setTravelerCounts }) => {
  // State for visibility of traveler dropdown
  const [isVisibleTraveler, setIsVisibleTraveler] = useState(false);
  // Compute total number of travelers
  const totalTraveler = Object.values(travelerCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  // Set traveler data to localStorage whenever travelerCounts changes
  useEffect(() => {
    localStorage.setItem("adults", travelerCounts.adults);
    localStorage.setItem("children", travelerCounts.children);
    localStorage.setItem("kids", travelerCounts.kids);
    localStorage.setItem("infants", travelerCounts.infants);
    localStorage.setItem("totalTraveler", totalTraveler);
  }, [travelerCounts, totalTraveler]);

  // Toggle visibility of traveler dropdown
  const handleTravelerVisibility = () => {
    setIsVisibleTraveler((prevState) => !prevState);
  };

  // Generic function to handle increment/decrement actions
  const updateTravelerCount = (type, increment) => {
    setTravelerCounts((prevCounts) => {
      // Calculate the new count based on increment value
      let newCount = prevCounts[type] + increment;

      // Check for conditions to prevent invalid state
      if (type === "adults" && newCount < 1) {
        newCount = 1;
        toast.info("At least one traveler must be selected!");
      }

      if (
        newCount < 0 ||
        (totalTraveler >= 9 && increment > 0) ||
        (type === "infants" && newCount > prevCounts.adults)
      ) {
        toast.error(
          `Cannot ${increment > 0 ? "increment" : "decrement"} ${type}!`
        );
        return prevCounts;
      }

      // Check if there is at least one traveler
      if (totalTraveler === 1 && increment < 0) {
        toast.info("At least one traveler must be selected!");
        return prevCounts;
      }

      return { ...prevCounts, [type]: newCount };
    });
  };

  // Handle change in input field
  const handleInputChange = (type, value) => {
    const parsedValue = parseInt(value, 10);
    setTravelerCounts((prevCounts) => {
      const newCount = isNaN(parsedValue) ? 0 : Math.max(0, parsedValue);
      if (type === "adults" && newCount < 1) {
        toast.info("At least one traveler must be selected!");
        return prevCounts;
      }
      return {
        ...prevCounts,
        [type]: newCount,
      };
    });
  };

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisibleTraveler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {}, [isVisibleTraveler]);

  return (
    <div className="relative w-full md:w-auto">
      {/* Toggle Button */}
      <div
        ref={buttonRef}
        className="md:w-full flex md:bg-[#e1eee8] bg-green-700/15 text-black text-xs md:text-xs items-center justify-center py-1.5 md:py-1 px-3 rounded-xl md:rounded-xl cursor-pointer"
        onClick={handleTravelerVisibility}
      >
        <div>
          {totalTraveler} Traveler{totalTraveler > 1 ? "s" : ""}
        </div>
        <MdOutlineKeyboardArrowDown fontSize={20} />
      </div>

      {/* Traveler Dropdown */}
      {isVisibleTraveler ? (
        <div
          ref={dropdownRef}
          className="p-2 mx-auto bg-white text-black border rounded-lg shadow-lg w-full md:w-64 absolute left-0 top-10 z-10"
        >
          {["adults", "children", "kids", "infants"].map((type, index) => {
            const label = type.charAt(0).toUpperCase() + type.slice(1);
            const minLabel =
              type === "children" ? "5-12" : type === "kids" ? "2-5" : "";
            const isDisabled = totalTraveler >= 9;

            return (
              <div
                key={index}
                className={`flex justify-between mb-2 border-b `}
              >
                <div className="text-start">
                  <label className="block text-sm text-black">
                    {label} {minLabel}
                  </label>
                </div>
                <div className="flex items-center">
                  <button
                    className="text-xl"
                    onClick={() => updateTravelerCount(type, -1)}
                    aria-label={`Decrease ${label}`}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    className={`w-7 md:w-16 text-center ${
                      isDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    value={travelerCounts[type]}
                    onChange={(e) => handleInputChange(type, e.target.value)}
                    aria-label={`Number of ${label}`}
                    disabled={isDisabled}
                  />
                  <button
                    className="text-xl text-green-500"
                    onClick={() => updateTravelerCount(type, 1)}
                    aria-label={`Increase ${label}`}
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TravelarCounter;
